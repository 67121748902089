<template>
  <el-dialog
    :title="isEdit === true ? '编辑' : '新增'"
    :visible.sync="show"
    width="800px"
    center
  >
    <el-form
     v-if="show"
      :model="form"
      :label-width="formLabelWidth"
      label-position="left"
      ref="form"
      :rules="rules"
    >
      <el-form-item label="终端id" prop="terminal_id">
        <el-input v-model="form.terminal_id" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="终端名称" prop="title">
        <el-input v-model="form.title" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="责任人" prop="cdo">
        <el-input v-model="form.cdo" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="密钥" prop="secret_key">
        <el-input v-model="form.secret_key" autocomplete="off"></el-input>
        <el-button @click="generateSecret" type="info">随机密钥</el-button>
      </el-form-item>

      <el-form-item label="是否启用" prop="is_show">
        <el-switch v-model="form.enable"></el-switch>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import API from "@/api";

export default {
  name: "addForm",
  data() {
    return {
      formLabelWidth: "100px",
      form: {
        enable: true,
        terminal_id: "",
        title: "",
        secret_key: "",
        cdo: "",
      },
      isEdit: false,
      show: false,
      cateData: [],
      rules: {
        terminal_id: [
          { required: true, message: "请输入终端id", trigger: "blur" },
        ],
        title: [{ required: true, message: "请输入终端名称", trigger: "blur" }],
        cdo: [{ required: true, message: "请输入责任人", trigger: "blur" }],
        secret_key: [
          { required: true, message: "请输入密钥", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    generateSecret() {
      this.$set(this.form, "secret_key", this.$md5(Date.now().toString()));
    },
    submit() {
      const funName = this.isEdit ? "updateData" : "addData";
      this.$refs.form.validate((valid) => {
        valid ? this[funName]() : "";
      });
    },
    updateData() {
      API.updateTerminalList(this.form)
        .then((res) => {
          if(!res.code){
            this.$message.success("编辑成功");
            this.show = false;
            this.$parent.getList();
          }else{
            this.$message.error(res.msg);
            this.show = false;
          }
          
          this.show = false;
          this.$parent.getList();
        })
        .catch(() => {
          this.$message.error("编辑失败");
        });
    },
    editData(data) {
      this.form = data;
      this.isEdit = true;
      this.show = true;
    },
    clearData() {
      this.form = {
        enable: true,
        terminal_id: "",
        title: "",
        secret_key: "",
        cdo: "",
      };
    },
    addFormData() {
      this.isEdit = false;
      this.clearData();
      this.show = true;
    },
    addData() {
      API.createTerminalList(this.form)
        .then((res) => {
          if(!res.code){
            this.$message.success("创建成功");
            this.show = false;
            this.$parent.getList();
          }else{
            this.$message.error(res.msg);
            this.show = false;
          }
        })
        .catch(() => {
          this.$message.error("创建失败");
        });
    },
  },
};
</script>

<style scoped>
</style>
